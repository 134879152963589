import React from "react";
import LpLayout from "../../../components/Layout/lp-layout";
import SEO from "../../../components/seo";
import ThankYou from "../../../components/ConversionPath/demo-requested";
// import Image from '../../components/image';
import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
import { restaurantTestimonials } from "../../../data/restaurant-data";
import chevron from "../../../images/svg/chevron_left.svg";

export default function ThankYouPage() {
  const cta = (
    <a
      href="/SpotOn_WhitePaper1_How_to_Grow_Your_Restaurants_Brand_by_Improving_the_Guest_Experience_R4.pdf"
      download="SpotOn_WhitePaper1_How_to_Grow_Your_Restaurants_Brand_by_Improving_the_Guest_Experience_R4.pdf"
      className="cta-primary text-white  hover:text-white hover:opacity-[.85] transition-all duration-300"
    >
      Download
      <img
        src={chevron}
        alt="chevron icon"
        style={{ margin: "0px 5px 2px 10px" }}
      />
    </a>
  );

  return (
    <LpLayout>
      <SEO title="Thank you - your request for a gated asset has been received | SpotOn " />

      <ThankYou
        noPhoneMessage
        thankYouMessage="You can download your whitepaper here:"
        customCta={cta}
      />

      <TestmonialReviews sectionData={restaurantTestimonials} />
    </LpLayout>
  );
}
